import { useState, useCallback } from 'react';
import axios from 'axios';
import { jwtDecode } from 'jwt-decode';
import io from 'socket.io-client';

export const useAuth = (socket, setSocket) => {
    const [user, setUser] = useState(null);

    const handleLogin = useCallback((userData) => {
        setUser(userData);
        
        // Store user data and tokens
        localStorage.setItem('user', JSON.stringify(userData));
        if (userData.token) {
            localStorage.setItem('authToken', userData.token);
        }
        if (userData.refreshToken) {
            localStorage.setItem('refreshToken', userData.refreshToken);
        }

        // Disconnect existing socket if any
        if (socket) {
            socket.disconnect();
        }

        // Create new socket with auth token
        const newSocket = io(process.env.REACT_APP_SOCKET_URL, {
            auth: { token: userData.token },
            reconnection: true,
            reconnectionDelay: 1000,
            reconnectionAttempts: 5
        });

        // Wait for socket to be fully connected before setting it
        newSocket.on('connect', () => {
            console.log('Socket connected after login');
            setSocket(newSocket); // Only set socket after connection is established
        });

        newSocket.on('connect_error', (err) => {
            console.error('Socket connection error:', err);
        });
    }, [socket, setSocket]);

    const handleLogout = useCallback((rooms) => {
        if (socket && user) {
            socket.emit('logout', { username: user.alias, rooms });
        }
        setUser(null);
        localStorage.removeItem('user');
        localStorage.removeItem('authToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('rooms');
    }, [socket, user]);

    const refreshAccessToken = async (refreshToken) => {
        try {
            const response = await axios.post(`${process.env.REACT_APP_SOCKET_URL}/refreshToken`, {
                refreshToken
            });
            return response.data.token;
        } catch (error) {
            console.error("Failed to refresh access token:", error);
            return null;
        }
    };

    const isTokenExpired = (token) => {
        if (!token) return true;
        
        try {
            const parts = token.split('.');
            if (parts.length !== 3) {
                console.error('Invalid token format');
                return true;
            }

            const decoded = jwtDecode(token);
            if (!decoded.exp) {
                console.error('Token has no expiration');
                return true;
            }

            // Add a 60-second buffer to prevent edge cases
            return decoded.exp * 1000 < (Date.now() + 60000);
        } catch (error) {
            console.error('Error decoding token:', error);
            return true;
        }
    };

    return {
        user,
        setUser,
        handleLogin,
        handleLogout,
        refreshAccessToken,
        isTokenExpired
    };
}; 