export const LoadingSpinner = () => (
    <div className="grid place-items-center h-screen bg-[#181818]">
        <div className="text-center">
            <div 
                className="animate-spin inline-block w-16 h-16 border-[4px] border-current border-t-transparent text-vp-c-green-2 rounded-full" 
                role="status" 
                aria-label="loading"
            />
            <p className="mt-4 text-xl font-semibold text-vp-c-text">Loading...</p>
        </div>
    </div>
); 