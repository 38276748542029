import React, { useEffect, useRef, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useFetchTickers } from '../hooks/useFetchTickers';
import { DEFAULT_ROOMS } from '../../config/roomConfig';

function RoomWrapper({
    joinRoom: originalJoinRoom,
    socketReady
}) {
    const { prefixAndRoom } = useParams();
    const navigate = useNavigate();
    const [prefix, roomName] = prefixAndRoom.split('-');
    const { availableTickers, loading: tickersLoading } = useFetchTickers();
    const hasRedirected = useRef(false);
    const attemptedRoom = useRef(null);

    const joinRoom = useCallback(
        (...args) => originalJoinRoom(...args),
        [originalJoinRoom]
    );

    useEffect(() => {
        if (tickersLoading || !prefix || !roomName || hasRedirected.current) return;

        // First check if it's a default room
        const defaultRoom = DEFAULT_ROOMS.find(
            room => room.ticker.toUpperCase() === roomName.toUpperCase()
        );

        if (defaultRoom) {
            attemptedRoom.current = {
                name: defaultRoom.name,
                ticker: defaultRoom.ticker,
                country: 'ALL',
                type: defaultRoom.type
            };
            return;
        }

        // If not a default room, check available tickers
        let tickers;
        switch (prefix.toLowerCase()) {
            case 'stock':
                tickers = availableTickers.stocks;
                break;
            case 'crypto':
                tickers = availableTickers.crypto;
                break;
            case 'index':
                tickers = availableTickers.index;
                break;
            case 'commodity':
                tickers = availableTickers.commodity;
                break;
            default:
                tickers = [];
        }

        const room = tickers?.find(ticker => {
            const normalizedTickerName = ticker.ticker.toUpperCase().trim();
            const normalizedRoomName = roomName.toUpperCase().trim();
            return normalizedTickerName === normalizedRoomName;
        });

        if (room) {
            attemptedRoom.current = {
                name: room.name,
                ticker: room.ticker,
                country: room.country,
                type: room.type
            };
        } else {
            navigate('/');
        }
        
    }, [prefix, roomName, availableTickers, tickersLoading, navigate]);

    // Modified second effect to check for socketReady
    useEffect(() => {
        if (!attemptedRoom.current || hasRedirected.current || !socketReady) {
            return;
        }

        const room = attemptedRoom.current;
        console.log('Socket ready, joining room:', room);
        joinRoom(room.name, room.ticker, room.country, room.type);
        hasRedirected.current = true;
        navigate('/');
        
    }, [joinRoom, navigate, socketReady]);

    if (tickersLoading) {
        return (
            <div className="flex items-center justify-center h-screen bg-[#181818]">
                <div className="text-center">
                    <div
                        className="animate-spin inline-block w-16 h-16 border-[4px] border-current border-t-transparent text-vp-c-green-2 rounded-full"
                        role="status"
                        aria-label="loading"
                    ></div>
                    <p className="mt-4 text-xl font-semibold text-vp-c-text">
                        Loading...
                    </p>
                </div>
            </div>
        );
    }

    return null;
}

export default RoomWrapper;
