import { useState, useEffect, useCallback } from 'react';

export function useSocket(socket, activeRoom) {
    const [messages, setMessages] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const [unreadCounts, setUnreadCounts] = useState({});
    const [allMessages, setAllMessages] = useState({});
    const [trendingRooms, setTrendingRooms] = useState(new Map());

    const sortMessages = useCallback(
        (msgs) => [...msgs].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp)),
        []
    );

    const extractRoomInfo = (msg) => {
        let roomType = 'all';
        let ticker = 'GENERAL';

        if (msg.room) {
            // Check if room contains a colon (indicating it's already in format "type:ticker")
            if (msg.room.includes(':')) {
                [roomType, ticker] = msg.room.split(':');
            } else {
                roomType = msg.room;
                ticker = msg.ticker || 'GENERAL';
            }
        }

        return {
            roomType: roomType.toLowerCase(),
            ticker: ticker.toUpperCase()
        };
    };

    // Update messages whenever active room changes
    useEffect(() => {
        if (activeRoom) {
            const roomKey = `${activeRoom.type}:${activeRoom.ticker}`.toLowerCase();
            setMessages(allMessages[roomKey] || []);
            
            // Clear unread count for the active room
            setUnreadCounts(prev => ({
                ...prev,
                [roomKey]: 0
            }));
        } else {
            setMessages([]);
        }
    }, [activeRoom, allMessages]);

    const addMessage = useCallback(
        (msg) => {
            const { roomType, ticker } = extractRoomInfo(msg);
            const messageRoomKey = `${roomType}:${ticker}`.toLowerCase();
            const activeRoomKey = activeRoom ? `${activeRoom.type}:${activeRoom.ticker}`.toLowerCase() : null;
            
            // Store message in the appropriate room's message list
            setAllMessages(prev => {
                const roomMessages = prev[messageRoomKey] || [];
                return {
                    ...prev,
                    [messageRoomKey]: sortMessages([...roomMessages, msg])
                };
            });

            // Update current messages if the message is for the active room
            if (messageRoomKey === activeRoomKey) {
                setMessages(prev => sortMessages([...prev, msg]));
            } else {
                setUnreadCounts(prev => ({
                    ...prev,
                    [messageRoomKey]: (prev[messageRoomKey] || 0) + 1
                }));
            }
        },
        [activeRoom, sortMessages]
    );

    const handlePreviousMessages = useCallback((msgs) => {
        
        // Group messages by room, ensuring we extract room and ticker correctly
        const messagesByRoom = {};
        msgs.forEach(msg => {
            const { roomType, ticker } = extractRoomInfo(msg);
            const roomKey = `${roomType}:${ticker}`.toLowerCase();
            
            if (!messagesByRoom[roomKey]) {
                messagesByRoom[roomKey] = [];
            }
            messagesByRoom[roomKey].push(msg);
        });

        // Sort messages in each room
        Object.keys(messagesByRoom).forEach(roomKey => {
            messagesByRoom[roomKey] = sortMessages(messagesByRoom[roomKey]);
        });

        // Update allMessages by merging with existing messages instead of replacing
        setAllMessages(prev => ({
            ...prev,
            ...messagesByRoom
        }));

        // Set current messages if there's an active room
        if (activeRoom) {
            const roomKey = `${activeRoom.type}:${activeRoom.ticker}`.toLowerCase();
            if (messagesByRoom[roomKey]) {
                setMessages(messagesByRoom[roomKey]);
            }
        }
    }, [activeRoom, sortMessages]);

    const handleMessageEdit = useCallback((data) => {
        const { messageId, newContent } = data;
        setAllMessages(prev => {
            const updated = { ...prev };
            Object.keys(updated).forEach(roomKey => {
                updated[roomKey] = updated[roomKey].map(msg => 
                    msg._id === messageId 
                        ? { ...msg, message: newContent, edited: true }
                        : msg
                );
            });
            return updated;
        });
    }, []);

    const handleMessageDelete = useCallback((data) => {
        const { messageId } = data;
        setAllMessages(prev => {
            const updated = { ...prev };
            Object.keys(updated).forEach(roomKey => {
                updated[roomKey] = updated[roomKey].filter(msg => msg._id !== messageId);
            });
            return updated;
        });

        // Also update the current messages array
        setMessages(prev => prev.filter(msg => msg._id !== messageId));
    }, []);

    const handleMessageReaction = useCallback((data) => {
        const { messageId, reactions } = data;
        setAllMessages(prev => {
            const updated = { ...prev };
            Object.keys(updated).forEach(roomKey => {
                updated[roomKey] = updated[roomKey].map(msg => 
                    msg._id === messageId 
                        ? { ...msg, reactions }
                        : msg
                );
            });
            return updated;
        });
    }, []);

    useEffect(() => {
        if (!socket) return;

        const messageHandlers = {
            previousMessages: handlePreviousMessages,
            message: addMessage,
            messageError: ({ reason }) => setErrorMessage(reason),
            removeMessages: ({ username }) => {
                setAllMessages(prev => {
                    const updated = { ...prev };
                    Object.keys(updated).forEach(roomKey => {
                        updated[roomKey] = updated[roomKey].filter(msg => msg.username !== username);
                    });
                    return updated;
                });
                setMessages(prev => prev.filter(msg => msg.username !== username));
            },
            messageEdited: handleMessageEdit,
            messageDeleted: handleMessageDelete,
            messageReacted: handleMessageReaction,
        };

        for (const [event, handler] of Object.entries(messageHandlers)) {
            socket.on(event, handler);
        }

        return () => {
            for (const event of Object.keys(messageHandlers)) {
                socket.off(event);
            }
        };
    }, [socket, addMessage, handlePreviousMessages, handleMessageEdit, handleMessageDelete, handleMessageReaction]);

    useEffect(() => {
        if (!socket) return;

        const handleRoomActivity = ({ room, type, ticker, timestamp }) => {
            
            setTrendingRooms(prev => {
                const newMap = new Map(prev);
                const roomKey = room; // room is already in format "type:ticker"
                
                // Don't increment counter for active room
                if (activeRoom && `${activeRoom.type}:${activeRoom.ticker}`.toLowerCase() === roomKey.toLowerCase()) {
                    return newMap;
                }

                const currentData = newMap.get(roomKey) || { type, ticker, activityCount: 0 };
                
                const updatedData = {
                    ...currentData,
                    type,
                    ticker,
                    lastActivity: timestamp,
                    activityCount: currentData.activityCount + 1
                };
                newMap.set(roomKey, updatedData);
                return newMap;
            });
        };

        socket.on('roomActivity', handleRoomActivity);

        // Cleanup old trending rooms every minute
        const cleanup = setInterval(() => {
            setTrendingRooms(prev => {
                const newMap = new Map(prev);
                const fiveMinutesAgo = Date.now() - 5 * 60 * 1000;
                
                for (const [room, data] of newMap.entries()) {
                    if (new Date(data.lastActivity) < fiveMinutesAgo) {
                        newMap.delete(room);
                    }
                }
                return newMap;
            });
        }, 60000);

        return () => {
            socket.off('roomActivity', handleRoomActivity);
            clearInterval(cleanup);
        };
    }, [socket, activeRoom]);

    // Add this effect to handle clearing activity counts when active room changes
    useEffect(() => {
        if (activeRoom) {
            setTrendingRooms(prev => {
                const newMap = new Map(prev);
                const activeRoomKey = `${activeRoom.type.toLowerCase()}:${activeRoom.ticker.toLowerCase()}`;
                
                // Clear the activity count for the room we're joining
                if (newMap.has(activeRoomKey)) {
                    const roomData = newMap.get(activeRoomKey);
                    newMap.set(activeRoomKey, {
                        ...roomData,
                        activityCount: 0
                    });
                }
                
                return newMap;
            });
        }
    }, [activeRoom]);

    const handleSendMessage = (message, replyTo = null) => {
        if (!socket) return;

        const messageData = {
            message,
            room: activeRoom,
            replyTo // Include reply information
        };

        socket.emit('sendMessage', messageData);
    };

    return { 
        messages, 
        errorMessage, 
        setMessages, 
        setErrorMessage, 
        unreadCounts, 
        trendingRooms,
        handleEdit: (messageId, newContent) => socket?.emit('editMessage', { messageId, newContent }),
        handleDelete: (messageId) => socket?.emit('deleteMessage', { messageId }),
        handleReaction: (messageId, reaction) => socket?.emit('reactToMessage', { messageId, reaction }),
        handleSendMessage
    };
}