import { useState, useEffect, useCallback } from 'react';
import basePayload from '../../config/base_payload.json';

// Create a shared state object outside the hook
let sharedState = {
  availableTickers: {
    stocks: basePayload.updates.stocks.map(stock => ({
      ...stock,
      usersOnline: 0,
      lastUpdated: 0
    })),
    crypto: basePayload.updates.cryptos.map(crypto => ({
      ...crypto,
      usersOnline: 0,
      lastUpdated: 0
    })),
    index: basePayload.updates.indexes.map(index => ({
      ...index,
      usersOnline: 0,
      lastUpdated: 0
    })),
    commodity: basePayload.updates.commodity.map(commodity => ({
      ...commodity,
      usersOnline: 0,
      lastUpdated: 0
    })),
    all: []
  },
  loading: false,
  error: null,
  lastFetchTime: 0,
  subscribers: new Set(),
  fetchPromise: null
};

const sortTickers = (tickers) => {
  const allRoomUsersZero = tickers.every((ticker) => (ticker.usersOnline || 0) === 0);
  return tickers.sort((a, b) => {
    if (allRoomUsersZero) {
      return (b.mcap || 0) - (a.mcap || 0);
    }
    return (b.usersOnline || 0) - (a.usersOnline || 0);
  });
};

export function useFetchTickers() {
  const [, setUpdateTrigger] = useState(0);

  const mergeData = (currentData, newData) => {
    const mergedData = newData.map((newItem) => {
      const existingItem = currentData.find(
        (item) => item.ticker === newItem.ticker
      );
      return existingItem ? { ...existingItem, ...newItem } : newItem;
    });
    return mergedData;
  };

  const fetchTickers = useCallback(async () => {
    const now = Date.now();
    
    if (sharedState.fetchPromise) {
      return sharedState.fetchPromise;
    }

    if (now - sharedState.lastFetchTime < 60000) {
      return;
    }

    if (document.hidden) {
      return;
    }

    sharedState.fetchPromise = (async () => {
      try {
        sharedState.loading = true;
        sharedState.subscribers.forEach(notify => notify());

        // Create a minimal payload with only necessary data
        const payload = {
          currentState: {
            stocks: sharedState.availableTickers.stocks.map(({ ticker, usersOnline }) => ({
              ticker,
              usersOnline
            })),
            crypto: sharedState.availableTickers.crypto.map(({ ticker, usersOnline }) => ({
              ticker,
              usersOnline
            })),
            index: sharedState.availableTickers.index.map(({ ticker, usersOnline }) => ({
              ticker,
              usersOnline
            })),
            commodity: sharedState.availableTickers.commodity.map(({ ticker, usersOnline }) => ({
              ticker,
              usersOnline
            }))
          },
          lastUpdated: {
            stocks: sharedState.availableTickers.stocks[0]?.lastUpdated,
            crypto: sharedState.availableTickers.crypto[0]?.lastUpdated,
            index: sharedState.availableTickers.index[0]?.lastUpdated,
            commodity: sharedState.availableTickers.commodity[0]?.lastUpdated,
            all: sharedState.availableTickers.all[0]?.lastUpdated
          }
        };

        const response = await fetch(process.env.REACT_APP_SOCKET_URL + "/tickers", {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(payload)
        });
        
        sharedState.lastFetchTime = now;
        
        if (response.status === 429) {
          throw new Error('Rate limit exceeded. Please wait before refreshing.');
        }
        if (response.status === 304) {
          return;
        }
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }

        const data = await response.json();

        // Update only changed data
        if (data.updates.stocks) {
          sharedState.availableTickers.stocks = sortTickers(
            mergeData(sharedState.availableTickers.stocks, data.updates.stocks)
          );
        }
        if (data.updates.cryptos) {
          sharedState.availableTickers.crypto = sortTickers(
            mergeData(sharedState.availableTickers.crypto, data.updates.cryptos)
          );
        }
        if (data.updates.indexes) {
          sharedState.availableTickers.index = sortTickers(
            mergeData(sharedState.availableTickers.index, data.updates.indexes)
          );
        }
        if (data.updates.commodity) {
          sharedState.availableTickers.commodity = sortTickers(
            mergeData(sharedState.availableTickers.commodity, data.updates.commodity)
          );
        }

        sharedState.subscribers.forEach(notify => notify());

      } catch (error) {
        console.error('Failed to fetch tickers:', error);
        sharedState.error = error;
        sharedState.subscribers.forEach(notify => notify());
      } finally {
        sharedState.loading = false;
        sharedState.fetchPromise = null;
      }
    })();

    return sharedState.fetchPromise;
  }, []);

  useEffect(() => {
    const notify = () => setUpdateTrigger(prev => prev + 1);
    sharedState.subscribers.add(notify);

    // Initial fetch only if we have no data
    if (sharedState.availableTickers.stocks.length === 0) {
      fetchTickers().catch(console.error);
    }

    // Set up interval
    const interval = setInterval(() => {
      fetchTickers().catch(console.error);
    }, 30000);

    return () => {
      clearInterval(interval);
      sharedState.subscribers.delete(notify);
    };
  }, [fetchTickers]);

  return {
    availableTickers: sharedState.availableTickers,
    loading: sharedState.loading,
    error: sharedState.error,
    fetchTickers,
  };
}
