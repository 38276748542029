import React from 'react';
import { Flame } from 'lucide-react';

export default function TrendingRooms({ trendingRooms, joinRoom, currentRooms }) {
    // Add safety check for undefined trendingRooms
    if (!trendingRooms) return null;

    // Convert Map to Array and sort by activity count
    const sortedRooms = Array.from(trendingRooms.entries())
        .map(([roomKey, data]) => ({
            roomKey,
            ...data
        }))
        .sort((a, b) => b.activityCount - a.activityCount)
        .filter(room => {
            // Filter out rooms that the user is already in
            const isJoined = currentRooms.some(
                currentRoom => `${currentRoom.type}:${currentRoom.ticker}` === room.roomKey
            );
            return !isJoined;
        })
        .slice(0, 5); // Show top 5 trending rooms

    return (
        <div className="bg-vp-c-bg-alt rounded border-vp-c-bg-elv p-2 mb-1">
            <div className="flex items-center gap-2 mb-2">
                <Flame size={16} className="text-vp-c-red-1" />
                <span className="text-sm font-medium text-vp-c-text">Trending Rooms</span>
            </div>
            <div className="space-y-1">
                {sortedRooms.length > 0 ? (
                    sortedRooms.map(({ roomKey, type, ticker, activityCount }) => (
                        <button
                            key={roomKey}
                            onClick={() => joinRoom(ticker, ticker, null, type)}
                            className="w-full p-2 rounded text-sm font-medium 
                                     bg-vp-c-bg-alt text-gray-300 hover:bg-vp-c-bg-elv 
                                     transition-all duration-300 ease-in-out
                                     flex items-center justify-between"
                        >
                            <span>{ticker}</span>
                            <span className="text-xs text-vp-c-red-1">
                                {activityCount} message{activityCount !== 1 ? 's' : ''}
                            </span>
                        </button>
                    ))
                ) : (
                    <div className="text-sm text-gray-500 text-center p-2">
                        No trending rooms available
                    </div>
                )}
            </div>
        </div>
    );
} 