import React, { useState } from 'react';
import axios from 'axios';
import { X } from 'lucide-react';
import { useError } from '../hooks/Error';

export default function LoginForm({ onLogin, isOpen, onClose }) {
  const [alias, setAlias] = useState('');
  const [password, setPassword] = useState('');
  const { errorMessage, triggerError, clearError } = useError(3000);
  const [loading, setLoading] = useState(false);

  const _handleLogin = async () => {
    clearError();

    // Validate alias
    if (!alias) {
      triggerError('Alias is required');
      return;
    }
    if (alias.length > 10) {
      triggerError('Alias must be no longer than 10 characters');
      return;
    }
    const aliasRegex = /^[a-zA-Z0-9]+$/;
    if (!aliasRegex.test(alias)) {
      triggerError('Alias must only contain alphanumeric characters');
      return;
    }

    // Validate password
    if (!password) {
      triggerError('Password is required');
      return;
    }
    if (password.length < 3) {
      triggerError('Password must be at least 3 characters long');
      return;
    }

    try {
      setLoading(true);
      const response = await axios.post(process.env.REACT_APP_SOCKET_URL + '/auth', {
        alias,
        password,
      });

      const { token, refreshToken } = response.data;
      
      // Create user data object with all necessary information
      const userData = {
        ...response.data,
        alias,
        token,
        refreshToken
      };

      // Pass the complete user data to onLogin
      onLogin(userData);
      onClose();
    } catch (error) {
      console.error('Login failed:', error.response ? error.response.data : error.message);
      triggerError(error.response?.data?.message || 'Login failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div className="bg-vp-c-bg-alt rounded-lg shadow-xl w-full max-w-md border border-vp-c-bg-elv overflow-hidden">
        <div className="flex justify-between items-center p-4 border-b border-vp-c-bg-elv">
          <h2 className="text-xl font-semibold text-vp-c-text">Login</h2>
          <button onClick={onClose} className="text-vp-c-gray-1 hover:text-vp-c-text">
            <X size={24} />
          </button>
        </div>

        {errorMessage && (
          <div className="bg-vp-c-red-soft text-vp-c-red-1 border-b-2 border-vp-c-red-2 text-center">
            {errorMessage}
          </div>
        )}

        <div className="p-4">
          <input
            type="text"
            value={alias}
            onChange={(e) => setAlias(e.target.value)}
            placeholder="Alias"
            required
            className="w-full p-3 mb-3 text-vp-c-text rounded border border-vp-c-gray-3 bg-vp-c-bg placeholder-vp-c-gray-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Password"
            className="w-full p-3 mb-3 text-vp-c-text rounded border border-vp-c-gray-3 bg-vp-c-bg placeholder-vp-c-gray-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>

        <button
          onClick={_handleLogin}
          disabled={loading} // Disable if loading or no token yet
          className={`px-8 py-3 rounded text-white mx-auto block transition-colors duration-150 ${
            loading ? 'bg-gray-500 cursor-not-allowed' : 'bg-vp-c-indigo-3 hover:bg-indigo-600'
          }`}
        >
          {loading ? 'Loading...' : 'Enter Chat'}
        </button>

        <p className="text-sm text-center mt-3 text-gray-400 py-2 mb-1 px-4">
          By logging in, you agree to our{' '}
          <a href="/tos" target="_blank" rel="noopener noreferrer" className="text-vp-c-indigo-3 underline hover:text-indigo-400">
            Terms of Service
          </a>
          ,{' '}
          <a href="/privacy" target="_blank" rel="noopener noreferrer" className="text-vp-c-indigo-3 underline hover:text-indigo-400">
            Privacy Policy
          </a>
          , and{' '}
          <a href="/rules" target="_blank" rel="noopener noreferrer" className="text-vp-c-indigo-3 underline hover:text-indigo-400">
            Rules
          </a>
          .
        </p>
      </div>
    </div>
  );
}
