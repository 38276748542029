import React, {
    useMemo,
    useState,
    useEffect,
    useCallback,
    useRef
} from 'react';
import { VariableSizeList as List } from 'react-window';
import LoginForm from '../auth/LoginForm';
import { Send, ChevronDown, Smile, Edit2, Trash2, MessageSquare, X } from 'lucide-react';
import Picker from 'emoji-picker-react';
import { useSocket } from '../hooks/Socket';

const DateBreakoff = React.memo(({ date }) => (
    <div className="text-center py-2 text-sm font-semibold text-gray-500">
        {date === new Date().toDateString() ? "Today" : date}
    </div>
));

const Message = React.memo(({ message, currentUser, onEdit, onDelete, onReply, socket, onHeightChange }) => {
    const [showActions, setShowActions] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editText, setEditText] = useState(message.message);
    const isUserMessage = message.username === currentUser?.alias?.toLowerCase();
    const isAdmin = currentUser?.isAdmin;
    const messageRef = useRef(null);

    // Calculate the number of buttons to determine the offset
    const buttonCount = 1 + 
        ((isUserMessage || isAdmin) ? 1 : 0) + 
        (isUserMessage ? 1 : 0);
    
    const marginOffset = buttonCount * 2 + 2;

    const handleEditSubmit = (e) => {
        e.preventDefault();
        if (editText.trim() !== message.message) {
            onEdit(message._id, editText.trim());
        }
        setIsEditing(false);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            handleEditSubmit(e);
        } else if (e.key === 'Escape') {
            setIsEditing(false);
            setEditText(message.message);
        }
    };

    useEffect(() => {
        if (messageRef.current) {
            const height = messageRef.current.getBoundingClientRect().height;
            onHeightChange(message._id, height);
        }
    }, [message.message, message.replyTo, onHeightChange, message._id]);

    return (
        <div 
            ref={messageRef} 
            className="group hover:bg-vp-c-bg-soft transition-colors duration-150 py-1"
            onMouseEnter={() => setShowActions(true)}
            onMouseLeave={() => setShowActions(false)}
        >
            <div className="px-2 relative">
                <div className="flex items-start">
                    <div className="flex-grow min-w-0">
                        {message.replyTo && message.replyTo.username && (
                            <div className="flex items-center text-xs text-gray-500 mb-1">
                                <MessageSquare size={12} className="mr-1" />
                                <span>Reply to </span>
                                <span className="font-medium ml-1" style={{ color: message.replyTo.color }}>
                                    @{message.replyTo.username}
                                </span>
                                <span className="truncate max-w-[200px] inline-block">
                                    : {message.replyTo.message}
                                </span>
                            </div>
                        )}
                        <div className="flex items-baseline">
                            <span className="text-xs text-vp-c-text flex-shrink-0">
                                {new Intl.DateTimeFormat('sv-SE', {
                                    hour: '2-digit',
                                    minute: '2-digit',
                                    second: '2-digit',
                                }).format(new Date(message.timestamp))}
                            </span>
                            <span className="ml-1 font-semibold flex-shrink-0" style={{ color: message.color }}>
                                {message.username}
                            </span>
                            <span className='text-vp-c-text flex-shrink-0'>:</span>
                            {isEditing ? (
                                <form onSubmit={handleEditSubmit} className="ml-1 flex-1">
                                    <div className="inline-flex items-center">
                                        <input
                                            type="text"
                                            value={editText}
                                            onChange={(e) => setEditText(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                            className="flex-1 min-w-0 bg-vp-c-bg-alt rounded-sm px-2 py-0.5 text-vp-c-text 
                                                focus:outline-none focus:ring-0"
                                            autoFocus
                                            maxLength={500}
                                        />
                                        <div className="ml-2 text-xs text-gray-500 flex-shrink-0">
                                            {editText.length}/500
                                        </div>
                                    </div>
                                </form>
                            ) : (
                                <span className="ml-1 text-vp-c-text break-words flex-1" style={{ lineHeight: '1.4' }}>
                                    {message.message}
                                    {message.edited && (
                                        <span className="text-xs text-gray-500 ml-1">(edited)</span>
                                    )}
                                </span>
                            )}
                        </div>
                        {isEditing && (
                            <div className="text-xs text-gray-400 mt-0.5 ml-14">
                                Press <kbd className="text-gray-300">Enter</kbd> to save • <kbd className="text-gray-300">Esc</kbd> to cancel
                            </div>
                        )}
                    </div>
                </div>

                <div 
                    className={`
                        fixed -translate-y-8 flex items-center gap-2 bg-vp-c-bg-alt rounded-lg px-2 py-1 shadow-lg
                        transition-opacity duration-200
                        ${showActions ? 'opacity-100' : 'opacity-0 pointer-events-none'}
                    `}
                    style={{ 
                        zIndex: 999,
                        marginLeft: `calc(100% - ${marginOffset}rem)`
                    }}
                >
                    <button
                        onClick={() => onReply(message)}
                        className="text-vp-c-text hover:text-blue-500 p-1"
                        title="Reply"
                    >
                        <MessageSquare size={16} />
                    </button>
                    {(isUserMessage || isAdmin) && (
                        <>
                            {isUserMessage && (
                                <button
                                    onClick={() => setIsEditing(true)}
                                    className="text-vp-c-text hover:text-blue-500 p-1"
                                    title="Edit"
                                >
                                    <Edit2 size={16} />
                                </button>
                            )}
                            <button
                                onClick={() => onDelete(message._id)}
                                className="text-vp-c-text hover:text-red-500 p-1"
                                title="Delete"
                            >
                                <Trash2 size={16} />
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    );
});

export function ChatArea({
    dimensions,
    messages,
    listRef,
    newMessage,
    setNewMessage,
    handleKeyDown,
    user,
    onLogin,
    activeRoom,
    socket
}) {
    const [isAtBottom, setIsAtBottom] = useState(true);
    const [showLoginForm, setShowLoginForm] = useState(false);
    const [isEmojiPickerVisible, setIsEmojiPickerVisible] = useState(false);
    const [replyingTo, setReplyingTo] = useState(null);

    const { handleEdit, handleDelete } = useSocket(socket, activeRoom);

    const systemMessage = useMemo(() => ({
        type: 'message',
        username: 'system',
        message: 'Welcome to the chat! Please be respectful of others.',
        color: '#888888',
        timestamp: new Date().toISOString()
    }), []);

    const handleEmojiClick = (emojiData) => {
        const emoji = emojiData.emoji;
        setNewMessage((prev) => prev + emoji);
        setIsEmojiPickerVisible(false);
    };

    useEffect(() => {
        setNewMessage('');
    }, [activeRoom, setNewMessage]);

    const getMessagesWithDates = useCallback((msgs) => {
        if (!Array.isArray(msgs)) {
            msgs = [];
        }

        const processedMessages = [];
        let lastMessageDate = null;
        const seenMessages = new Set();

        const validMessages = msgs.filter(message => {
            const isValid = message && message.timestamp;
            if (!isValid) {
                console.warn('Invalid message found:', message);
            }
            return isValid;
        });
        
        if (validMessages.length === 0 && activeRoom) {
            processedMessages.push({ type: 'date', date: new Date().toDateString() });
            processedMessages.push(systemMessage);
            return processedMessages;
        }

        validMessages.forEach((message) => {
            const uniqueKey = `${message.username}-${message.timestamp}-${message.message}`;
            if (seenMessages.has(uniqueKey)) {
                return;
            }
            
            seenMessages.add(uniqueKey);
            const messageDate = new Date(message.timestamp).toDateString();

            if (messageDate !== lastMessageDate) {
                processedMessages.push({ type: 'date', date: messageDate });
                lastMessageDate = messageDate;
            }

            processedMessages.push({
                ...message,
                type: 'message'
            });
        });

        return processedMessages;
    }, [activeRoom, systemMessage]);

    const chunkedMessages = useMemo(() => getMessagesWithDates(messages), [messages, getMessagesWithDates]);

    const messageHeights = useRef(new Map());
    
    const getItemSize = useCallback((index) => {
        const message = chunkedMessages[index];
        if (message.type === 'date') return 30;
        
        return messageHeights.current.get(message._id) || 50; // Default height
    }, [chunkedMessages]);
    
    const handleHeightChange = useCallback((messageId, height) => {
        if (messageHeights.current.get(messageId) !== height) {
            messageHeights.current.set(messageId, height);
            if (listRef.current) {
                listRef.current.resetAfterIndex(0);
            }
        }
    }, [listRef]);

    const handleScroll = useCallback((event) => {
        const { scrollOffset } = event;
        const scrollHeight = chunkedMessages.reduce((sum, _, i) => sum + (getItemSize(i) || 50), 0);
        const atBottom = scrollHeight - scrollOffset - dimensions.listHeight <= 30;
        setIsAtBottom(atBottom);
    }, [dimensions.listHeight, chunkedMessages, getItemSize]);

    const scrollToBottom = () => {
        if (listRef?.current) {
            listRef.current.scrollToItem(chunkedMessages.length - 1, 'end');
        }
    };

    useEffect(() => {
        if (listRef?.current && isAtBottom) {
            listRef.current.scrollToItem(chunkedMessages.length - 1, 'end');
        }
    }, [listRef, chunkedMessages.length, isAtBottom]);

    useEffect(() => {
        const timeoutIds = [];
        
        if (listRef.current && activeRoom && isAtBottom) {
            listRef.current.scrollToItem(chunkedMessages.length - 1, 'end');
            
            const id1 = setTimeout(() => {
                listRef.current?.scrollToItem(chunkedMessages.length - 1, 'end');
            }, 100);

            timeoutIds.push(id1);
        }

        return () => {
            timeoutIds.forEach(id => clearTimeout(id));
        };
    }, [activeRoom, chunkedMessages.length, isAtBottom, listRef]);

    const handleTextAreaFocus = () => {
        if (!user) setShowLoginForm(true);
    };

    const handleReply = useCallback((message) => {
        setReplyingTo({
            messageId: message._id,
            username: message.username,
            message: message.message
        });
    }, []);

    const handleSendWithReply = useCallback(() => {
        if (!socket || !newMessage.trim() || !activeRoom) return;

        const messageData = {
            ticker: activeRoom.ticker,
            type: activeRoom.type,
            message: newMessage
        };

        if (replyingTo) {
            messageData.replyTo = {
                messageId: replyingTo.messageId,
                username: replyingTo.username,
                message: replyingTo.message
            };
        }

        socket.emit('sendMessage', messageData);
        setNewMessage('');
        setReplyingTo(null);
    }, [socket, newMessage, activeRoom, replyingTo, setNewMessage]);

    const handleKeyPress = useCallback((e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            handleSendWithReply();
        }
    }, [handleSendWithReply]);

    useEffect(() => {
        socket?.on('messageReactionUpdate', ({ messageId, reactions }) => {
            if (listRef?.current) {
                listRef.current.resetAfterIndex(0);
            }
        });

        return () => {
            socket?.off('messageReactionUpdate');
        };
    }, [socket, listRef]);

    const renderMessageChunk = useCallback(({ index, style, data }) => {
        const { chunkedMessages } = data;
        const chunk = chunkedMessages[index];
        
        return (
            <div style={{
                ...style,
                height: getItemSize(index),
                overflow: 'hidden' // Ensure content doesn't overflow
            }}>
                {chunk.type === 'date' ? (
                    <DateBreakoff date={chunk.date} />
                ) : (
                    <Message
                        message={chunk}
                        currentUser={user}
                        onEdit={handleEdit}
                        onDelete={handleDelete}
                        onReply={handleReply}
                        socket={socket}
                        onHeightChange={handleHeightChange}
                    />
                )}
            </div>
        );
    }, [user, handleEdit, handleDelete, handleReply, socket, getItemSize, handleHeightChange]);

    return (
        <div className="flex flex-col h-full">
            <div className="relative flex-grow bg-vp-c-alt">
                {activeRoom ? (
                    <>
                        <List
                            height={dimensions.listHeight}
                            itemCount={chunkedMessages.length}
                            itemSize={getItemSize}
                            width="100%"
                            ref={listRef}
                            onScroll={handleScroll}
                            className="relative z-0"
                            overscanCount={5}
                            itemData={{ chunkedMessages }}
                        >
                            {renderMessageChunk}
                        </List>
                        {!isAtBottom && (
                            <button
                                onClick={scrollToBottom}
                                className="absolute bottom-4 right-4 bg-blue-600 text-white rounded-full p-2 shadow-lg ease-in-out hover:bg-blue-700 hover:scale-95 transition-colors z-10"
                                aria-label="Scroll to bottom"
                            >
                                <ChevronDown />
                            </button>
                        )}
                    </>
                ) : (
                    <div className="flex items-center justify-center h-full text-vp-c-text">
                        Welcome! Join a ticker room!
                    </div>
                )}
            </div>

            <div className="flex-shrink-0 p-2 bg-vp-c-bg">
                {showLoginForm ? (
                    <LoginForm
                        onLogin={onLogin}
                        isOpen={showLoginForm}
                        onClose={() => setShowLoginForm(false)}
                    />
                ) : (
                    <div className="relative">
                        {replyingTo && (
                            <div className="absolute bottom-full left-0 right-0 mb-2 px-2 py-1 bg-vp-c-bg-alt rounded-lg shadow-lg flex items-center justify-between">
                                <div className="flex-1 min-w-0">
                                    <div className="text-sm font-semibold text-vp-c-text">
                                        Replying to @{replyingTo.username}
                                    </div>
                                    <div className="text-sm text-gray-500 truncate">
                                        {replyingTo.message}
                                    </div>
                                </div>
                                <button
                                    onClick={() => setReplyingTo(null)}
                                    className="ml-2 text-gray-500 hover:text-gray-700 flex-shrink-0"
                                >
                                    <X size={16} />
                                </button>
                            </div>
                        )}

                        <div className="flex items-center space-x-2">
                            <div className="flex-1 relative">
                                <textarea
                                    value={newMessage}
                                    onChange={(e) => setNewMessage(e.target.value)}
                                    onFocus={handleTextAreaFocus}
                                    onKeyDown={handleKeyPress}
                                    placeholder="Type your message..."
                                    maxLength={500}
                                    className="w-full resize-none rounded bg-vp-c-bg-alt p-4 pb-4 h-14 focus:outline-none focus:ring-1 focus:ring-gray-500 focus:border-transparent text-vp-c-text placeholder-gray-400"
                                />
                                {newMessage.length > 0 && (
                                    <div className="absolute bottom-1.5 right-1 text-xs text-gray-400">
                                        {newMessage.length}/500
                                    </div>
                                )}
                            </div>

                            <button
                                onClick={() => setIsEmojiPickerVisible(!isEmojiPickerVisible)}
                                className="p-2 mb-1.5 bg-vp-c-yellow-1 text-gray-700 rounded hover:bg-vp-c-yellow-2 transition-colors duration-150"
                                aria-label="Open Emoji Picker"
                            >
                                <Smile />
                            </button>

                            <button
                                onClick={handleSendWithReply}
                                className="p-2 mb-1.5 bg-blue-600 text-white rounded hover:bg-blue-700 transition-colors duration-150"
                            >
                                <Send />
                            </button>
                        </div>

                        {isEmojiPickerVisible && (
                            <div className="absolute bottom-16 right-0 z-50">
                                <Picker
                                    onEmojiClick={handleEmojiClick}
                                    theme="dark"
                                    emojiStyle="native"
                                    lazyLoadEmojis="true"
                                />
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
}

