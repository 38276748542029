import { useState, useEffect, useCallback } from 'react';
import { DEFAULT_ROOMS } from '../../config/roomConfig';

export function useRooms(socket, alias, socketReady) {
  const [rooms, setRooms] = useState([]); 
  const [activeRoom, setActiveRoom] = useState(null);
  const [loading, setLoading] = useState(true);

  // Add a function to get the username (either alias or anonymous)
  const getUsername = useCallback(() => {
    if (alias) return alias;
    // Generate or get temporary anonymous ID from localStorage
    let anonId = localStorage.getItem('anonymousId');
    if (!anonId) {
      // Make sure the anonymous ID matches the format expected by the backend
      anonId = `anon_${Math.random().toString(36).substr(2, 9)}`;
      localStorage.setItem('anonymousId', anonId);
    }
    // Return the same ID for both username and alias to satisfy backend checks
    return anonId;
  }, [alias]);

  // Update socket.user mock for anonymous users
  useEffect(() => {
    if (socket && !alias) {
      // Mock the socket.user object for anonymous users
      socket.user = { alias: getUsername() };
    }
  }, [socket, alias, getUsername]);

  useEffect(() => {
    let storedRooms = [];
    let lastActiveRoom = null;

    // Only proceed if we have all required dependencies
    if (!socket || !socketReady) {
      setLoading(true);
      return;
    }

    try {
      storedRooms = JSON.parse(localStorage.getItem('rooms')) || [DEFAULT_ROOMS[0]];
      lastActiveRoom = JSON.parse(localStorage.getItem('lastActiveRoom'));
      
      // Check if we need to add General room
      if (!storedRooms.some(room => room.ticker === 'GENERAL')) {
        storedRooms.unshift(DEFAULT_ROOMS[0]);
      }
    } catch (error) {
      console.error('Invalid JSON in storage, clearing rooms:', error);
      localStorage.removeItem('rooms');
      localStorage.removeItem('lastActiveRoom');
      storedRooms = [DEFAULT_ROOMS[0]];
    }

    setRooms(storedRooms);
    setLoading(false);

    // Always join General room on mount/refresh
    socket.emit('joinRoom', {
      ticker: 'GENERAL',
      type: 'all',
      username: getUsername(),
    });

    // Handle active room setting and room joining
    if (lastActiveRoom && 
        lastActiveRoom.ticker !== 'GENERAL' && 
        storedRooms.some((room) =>
          room.ticker === lastActiveRoom.ticker &&
          room.type === lastActiveRoom.type)) {
      setActiveRoom(lastActiveRoom);
      socket.emit('joinRoom', {
        ticker: lastActiveRoom.ticker,
        type: lastActiveRoom.type,
        username: getUsername(),
      });
    } else {
      setActiveRoom(DEFAULT_ROOMS[0]);
    }

    return () => {
      if (socket && socketReady) {
        storedRooms.forEach(({ ticker, type }) => {
          if (ticker !== 'GENERAL') { // Don't leave General room on cleanup
            socket.emit('leaveRoom', {
              ticker,
              type,
              username: getUsername(),
            });
          }
        });
      }
    };
  }, [socket, socketReady, getUsername]);

  const joinRoom = useCallback(
    (roomName, ticker, country, type) => {
      if (!socket || !socketReady) return;

      setActiveRoom((prevActiveRoom) => {
        if (prevActiveRoom && (prevActiveRoom.ticker !== ticker || prevActiveRoom.type !== type)) {
          socket.emit('leaveRoom', {
            ticker: prevActiveRoom.ticker,
            type: prevActiveRoom.type,
            username: getUsername(),
          });
        }
        return prevActiveRoom;
      });

      socket.emit(
        'joinRoom',
        {
          ticker,
          type,
          username: getUsername(),
        },
        (response) => {
          if (response.error) {
            console.error(`Room Error: ${response.error}`);
            return;
          }

          setRooms((prevRooms) => {
            let updatedRooms = prevRooms.filter(
              (room) => !(room.ticker === ticker && room.type === type)
            );

            updatedRooms.unshift({ name: roomName, ticker, country, type });

            // Limit rooms to 10 while preserving General
            if (updatedRooms.length > 10) {
              const nonGeneralRooms = updatedRooms.filter(room => room.ticker !== 'GENERAL');
              const generalRoom = updatedRooms.find(room => room.ticker === 'GENERAL');
              
              const roomToLeave = nonGeneralRooms.pop();
              updatedRooms = [generalRoom, ...nonGeneralRooms];

              if (roomToLeave) {
                socket.emit('leaveRoom', {
                  ticker: roomToLeave.ticker,
                  type: roomToLeave.type,
                  username: getUsername(),
                });
              }

              setActiveRoom((prevActiveRoom) => {
                if (
                  roomToLeave &&
                  prevActiveRoom?.ticker === roomToLeave.ticker &&
                  prevActiveRoom?.type === roomToLeave.type
                ) {
                  return DEFAULT_ROOMS[0]; // Default to General if active room is removed
                }
                return prevActiveRoom;
              });
            }

            localStorage.setItem('rooms', JSON.stringify(updatedRooms));
            return updatedRooms;
          });

          setActiveRoom({ name: roomName, ticker, country, type });
          localStorage.setItem(
            'lastActiveRoom',
            JSON.stringify({ name: roomName, ticker, country, type })
          );
        }
      );
    },
    [socket, socketReady, getUsername]
  );

  const leaveRoom = useCallback(
    (ticker, type) => {
      if (!socket || !socketReady) return;
      
      // Prevent leaving General room
      if (ticker === 'GENERAL') return;

      const updatedRooms = rooms.filter(
        (room) => room.ticker !== ticker || room.type !== type
      );

      setRooms(updatedRooms);
      localStorage.setItem('rooms', JSON.stringify(updatedRooms));

      if (activeRoom?.ticker === ticker && activeRoom?.type === type) {
        const nextRoom = DEFAULT_ROOMS[0]; // Default to General when active room is left
        setActiveRoom(nextRoom);
        localStorage.setItem('lastActiveRoom', JSON.stringify(nextRoom));
      }

      socket.emit('leaveRoom', {
        ticker,
        type,
        username: getUsername(),
      });
    },
    [rooms, activeRoom, socket, socketReady, getUsername]
  );

  const clearRooms = useCallback(() => {
    if (!socket || !socketReady) return;

    rooms.forEach(({ ticker, type }) => {
      if (ticker !== 'GENERAL') { // Don't leave General room
        socket.emit('leaveRoom', {
          ticker,
          type,
          username: getUsername(),
        });
      }
    });

    setRooms([DEFAULT_ROOMS[0]]); // Keep only General room
    setActiveRoom(DEFAULT_ROOMS[0]); // Set General as active
    localStorage.setItem('rooms', JSON.stringify([DEFAULT_ROOMS[0]]));
    localStorage.setItem('lastActiveRoom', JSON.stringify(DEFAULT_ROOMS[0]));
  }, [rooms, socket, socketReady, getUsername]);

  return {
    rooms,
    activeRoom,
    joinRoom,
    leaveRoom,
    clearRooms,
    setRooms,
    loading
  };
}