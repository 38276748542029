import React, { useState } from 'react';
import LoginForm from '../auth/LoginForm'; // Import the LoginForm component
import { LogIn, LogOut } from 'lucide-react'; // Import the Login icon

const Header = ({ user, onLogout, onLogin }) => {
    const [showLoginForm, setShowLoginForm] = useState(false); // State to manage LoginForm visibility

    const handleLoginClick = () => {
        setShowLoginForm(true); // Open the LoginForm
    };

    const handleLoginClose = () => {
        setShowLoginForm(false); // Close the LoginForm
    };

    return (
        <>
            {showLoginForm && (
                <LoginForm
                    onLogin={onLogin}
                    isOpen={showLoginForm}
                    onClose={handleLoginClose}
                />
            )}
            <header className="sticky top-0 z-50 w-full bg-vp-c-bg border-b border-vp-c-bg-elv">
                <div className="w-full mx-auto px-4 sm:px-6 lg:px-4">
                    <div className="flex justify-between items-center h-16">
                        {/* Left section */}
                        <div className="flex-shrink-0 flex flex-col items-center">
                            <a href="/" className="flex items-center">
                                <img
                                    src="/logo192.png" // Path to the logo in the public folder
                                    alt="Tradechat Logo"
                                    className="block sm:hidden w-8 h-8"
                                />
                                <span className="hidden sm:block text-2xl font-bold text-vp-c-green-1 hover:text-vp-c-green-2 transition-colors">
                                    Tradechat
                                </span>
                            </a>
                            <span className="text-xs text-vp-c-indigo-2 font-bold opacity-60">
                                BETA
                            </span>
                        </div>

                        {/* Right section */}
                        <div className="flex-shrink-0 flex items-center space-x-4">
                            {user ? (
                                <>
                                    <span className="text-vp-c-text text-sm">
                                        <span className="font-medium text-white">{user.alias}</span>
                                    </span>
                                    <button
                                        onClick={onLogout}
                                        className="bg-vp-c-red-2 hover:bg-vp-c-red-3 ease-in-out duration-300 text-white rounded-md text-sm font-medium transition-colors"
                                    >
                                    <span className="hidden sm:block px-4 py-2 text-sm font-medium">Logout</span>
                                    <LogOut className="block sm:hidden w-8 h-8 p-1" aria-label="Logout" />
                                    </button>
                                </>
                            ) : (
                                <button
                                    onClick={handleLoginClick}
                                    className="flex items-center justify-center bg-vp-c-green-2 hover:bg-vp-c-green-3 ease-in-out duration-300 text-white rounded-md transition-colors"
                                >
                                    <span className="hidden sm:block px-4 py-2 text-sm font-medium">Login / Register</span>
                                    <LogIn className="block sm:hidden w-8 h-8 p-1" aria-label="Login / Register" />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
};

export default Header;
