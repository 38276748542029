import React, { useState, useEffect } from 'react';
import { Search, Bitcoin, X, Menu } from 'lucide-react';
import { RoomSearchModal } from './RoomSearchModal';
import Flag from 'react-world-flags';
import { ShareRoomButton } from '../modals/shareroom';

export function RoomTabs({ rooms: initialRooms, activeRoom, joinRoom, leaveRoom, trendingRooms }) {
  const [isSearchModalOpen, setIsSearchModalOpen] = useState(false);
  const [orderedRooms, setOrderedRooms] = useState([]);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    if (!activeRoom) {
      setOrderedRooms(initialRooms);
      return;
    }

    const activeRoomKey = `${activeRoom.type}:${activeRoom.ticker}`;
    const updatedRooms = [...initialRooms];
    const activeRoomIndex = updatedRooms.findIndex(
      room => `${room.type}:${room.ticker}` === activeRoomKey
    );

    if (activeRoomIndex > -1) {
      const [movedRoom] = updatedRooms.splice(activeRoomIndex, 1);
      updatedRooms.unshift(movedRoom);
    }

    setOrderedRooms(updatedRooms);
  }, [initialRooms, activeRoom]);

  return (
    <>
      <div className="bg-vp-c-bg-alt rounded border-vp-c-bg-elv p-2 mb-1 max-h-[90vh] flex flex-col relative">
        <button
          onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
          className="md:hidden flex items-center justify-between p-2 rounded bg-vp-c-indigo-soft text-vp-c-indigo-1 border-b-2 border-vp-c-indigo-2 hover:bg-vp-c-indigo-2 hover:text-vp-c-text transition-all"
        >
          <Menu size={20} className="mr-2" />
          <span className="text-sm font-medium flex-grow text-center">{activeRoom?.name || "Select Room"}</span>
        </button>

        <div className={`
          ${isMobileMenuOpen ? 'flex' : 'hidden'} 
          md:flex flex-col w-full
          absolute md:relative
          top-full md:top-auto
          left-0 md:left-auto
          bg-vp-c-bg-alt
          z-20 md:z-auto
          border md:border-0
          rounded
          p-2 md:p-0
          mt-1 md:mt-0
        `}>
          <div className="flex items-center justify-between mb-2 sticky top-0 bg-vp-c-bg-alt z-10">
            <button
              onClick={() => setIsSearchModalOpen(true)}
              className="p-2.5 rounded bg-vp-c-indigo-3 text-vp-c-text hover:bg-vp-c-indigo-2 transition-colors duration-300 ease-in-out flex items-center gap-1"
            >
              <Search size={16} />
              <span className="inline">Search</span>
            </button>
            <ShareRoomButton activeRoom={activeRoom} />
          </div>

          <div className="flex-1 overflow-y-auto pr-1 max-h-[50vh] md:max-h-none">
            {orderedRooms.map((room) => {
              const roomKey = `${room.type.toLowerCase()}:${room.ticker.toLowerCase()}`;
              const activityCount = trendingRooms.get(roomKey)?.activityCount || 0;
              
              const isNotActiveRoom = !activeRoom || 
                activeRoom.type.toLowerCase() !== room.type.toLowerCase() || 
                activeRoom.ticker.toLowerCase() !== room.ticker.toLowerCase();
              
              return (
                <button
                  key={roomKey}
                  onClick={() => {
                    joinRoom(room.name, room.ticker, room.country, room.type);
                    setIsMobileMenuOpen(false);
                  }}
                  className={`
                    relative w-full p-2 rounded text-sm font-medium 
                    transition-all duration-300 ease-in-out group flex items-center justify-between
                    mb-1
                    ${activeRoom?.ticker === room.ticker && activeRoom?.type === room.type
                      ? 'bg-vp-c-green-soft text-vp-c-green-1 border-b-2 border-vp-c-green-2'
                      : 'bg-vp-c-bg-alt text-gray-300 hover:bg-vp-c-bg-elv border-b-2 border-transparent'
                    }
                  `}
                >
                  <div className="flex items-center space-x-2">
                    {room.type === 'crypto' ? (
                      <Bitcoin size={20} className={`
                        transition-colors duration-300
                        ${activeRoom?.ticker === room.ticker && activeRoom?.type === room.type
                          ? 'text-vp-c-green-1'
                          : 'text-vp-c-gray-1'
                        }
                      `} />
                    ) : (
                      room.country && typeof room.country === 'string' && room.country.trim() !== '' && (
                        <Flag code={room.country} className="w-5 h-5" />
                      )
                    )}
                    <span>{room.ticker.toUpperCase()}</span>
                    
                    {activityCount > 0 && isNotActiveRoom && (
                      <span className="bg-vp-c-red-1 text-white text-xs px-2 py-0.5 rounded-full">
                        {activityCount > 99 ? '99+' : activityCount}
                      </span>
                    )}
                  </div>

                  {/* X Icon - Only show if not General room */}
                  {room.ticker !== 'GENERAL' && (
                    <div
                      role="button"
                      tabIndex="0"
                      className={`
                        p-1 rounded transition-all duration-200 ease-in-out focus:outline-none cursor-pointer
                        ${activeRoom?.ticker === room.ticker && activeRoom?.type === room.type
                          ? 'opacity-100 hover:bg-vp-c-green-soft/80'
                          : 'opacity-100 md:opacity-0 group-hover:opacity-100 hover:bg-gray-800'
                        }
                      `}
                      onClick={(e) => {
                        e.stopPropagation();
                        leaveRoom(room.ticker, room.type);
                      }}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter' || e.key === ' ') {
                          e.stopPropagation();
                          leaveRoom(room.ticker, room.type);
                        }
                      }}
                    >
                      <X
                        size={16}
                        className={`
                          transition-colors duration-200
                          ${activeRoom?.ticker === room.ticker && activeRoom?.type === room.type
                            ? 'text-vp-c-green-1 hover:text-vp-c-red-1'
                            : 'text-vp-c-red-1 hover:text-vp-c-red-2'
                          }
                        `}
                      />
                    </div>
                  )}
                </button>
              );
            })}
          </div>
        </div>
      </div>

      <RoomSearchModal
        isOpen={isSearchModalOpen}
        onClose={() => setIsSearchModalOpen(false)}
        joinRoom={joinRoom}
      />
    </>
  );
}