export const DEFAULT_ROOMS = [
  {
    name: 'General',
    ticker: 'GENERAL',
    type: 'all',
  },
  {
    name: 'Stocks chat',
    ticker: 'STOCKS',
    type: 'all',
  },
  {
    name: 'Index chat',
    ticker: 'INDEX',
    type: 'all',
  },
  {
    name: 'Crypto chat',
    ticker: 'CRYPTO',
    type: 'all',
  },
]; 