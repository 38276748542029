import React, { useState, useRef, useEffect } from 'react';
import { Search, X, Bitcoin } from 'lucide-react'; // Import the Bitcoin icon from lucide-react
import Flag from 'react-world-flags';
import { useFetchTickers } from '../hooks/useFetchTickers';  // Import the custom hook

export function RoomSearchModal({ isOpen, onClose, joinRoom }) {
  const [activeTab, setActiveTab] = useState('stocks');
  const [searchTerm, setSearchTerm] = useState('');
  const modalRef = useRef(null);
  const searchInputRef = useRef(null);
  const { availableTickers } = useFetchTickers();

  // Filtered items based on active tab and search term
  const filteredItems = (activeTab === 'stocks' ? availableTickers.stocks
    : activeTab === 'crypto' ? availableTickers.crypto
    : activeTab === 'index' ? availableTickers.index
    : availableTickers.commodity)
    .filter((item) =>
      (item.ticker && item.ticker.toLowerCase().includes(searchTerm.toLowerCase())) ||
      (item.name && item.name.toLowerCase().includes(searchTerm.toLowerCase()))
    );

  // Close modal on outside click
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [isOpen, onClose]);

  // Autofocus on search input when modal is opened
  useEffect(() => {
    if ((isOpen || activeTab) && searchInputRef.current) {
      searchInputRef.current.focus();
    }
  }, [isOpen, activeTab]);

  if (!isOpen) return null;
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50 p-4">
      <div
        ref={modalRef}
        className="bg-vp-c-bg-alt rounded-lg shadow-xl w-full max-w-md max-h-[80vh] border border-vp-c-bg-elv overflow-hidden"
      >
        <div className="flex justify-between items-center p-4 border-b border-vp-c-bg-elv">
          <h2 className="text-xl font-semibold text-vp-c-text">Search Ticker</h2>
          <button onClick={onClose} className="text-vp-c-gray-1 hover:text-vp-c-text">
            <X size={24} />
          </button>
        </div>

        <div className="flex border-b border-vp-c-bg-elv">
          <button
            onClick={() => setActiveTab('stocks')}
            className={`flex-1 p-3 ${activeTab === 'stocks' ? 'bg-vp-c-indigo-soft text-vp-c-indigo-1 border-b-2 border-vp-c-indigo-2' : 'text-vp-c-gray-1 hover:bg-vp-c-bg-soft'}`}
          >
            Stocks
          </button>
          <button
            onClick={() => setActiveTab('crypto')}
            className={`flex-1 p-3 ${activeTab === 'crypto' ? 'bg-vp-c-purple-soft text-vp-c-purple-1 border-b-2 border-vp-c-purple-2' : 'text-vp-c-gray-1 hover:bg-vp-c-bg-soft'}`}
          >
            Crypto
          </button>
          <button
            onClick={() => setActiveTab('index')}
            className={`flex-1 p-3 ${activeTab === 'index' ? 'bg-vp-c-green-soft text-vp-c-green-1 border-b-2 border-vp-c-green-2' : 'text-vp-c-gray-1 hover:bg-vp-c-bg-soft'}`}
          >
            Index
          </button>
          <button
            onClick={() => setActiveTab('commodity')}
            className={`flex-1 p-3 ${activeTab === 'commodity' ? 'bg-vp-c-red-soft text-vp-c-red-1 border-b-2 border-vp-c-red-2' : 'text-vp-c-gray-1 hover:bg-vp-c-bg-soft'}`}
          >
            Commodity
          </button>
        </div>

        <div className="p-4">
          <div className="flex items-center border border-vp-c-gray-3 rounded-lg bg-vp-c-bg">
            <Search size={20} className="ml-3 text-vp-c-gray-1" />
            <input
              ref={searchInputRef} // Attach reference to the input
              type="text"
              placeholder={`Search ${activeTab === 'stocks' ? 'Stocks' : activeTab === 'crypto' ? 'Crypto' : activeTab === 'index' ? 'Index' : 'Commodity'}`}
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="w-full p-2 pl-2 bg-transparent text-vp-c-text focus:outline-none placeholder-vp-c-gray-1"
            />
          </div>
        </div>

        <div className="max-h-64 overflow-y-auto">
          {
            filteredItems.slice(0, 20).map((item) => (
              <button
                key={`${item.type}:${item.ticker}`}
                onClick={() => {
                  if (joinRoom) {
                    joinRoom(item.name, item.ticker, item.country, item.type);
                    onClose();
                  } else {
                    console.error('joinRoom function not provided');
                  }
                }}
                className="w-full text-left p-3 hover:bg-vp-c-bg-soft border-b border-vp-c-bg-elv last:border-b-0 flex justify-between items-center"
              >
                <div className="flex items-center">
                  {item.type === 'crypto' ? (
                    <Bitcoin size={20} className="mr-2 text-vp-c-gray-1" />
                  ) : (
                    item.country && <Flag code={item.country} className="w-5 h-5 mr-2" />
                  )}
                  <span className="font-semibold text-vp-c-text">{item.ticker}</span>
                </div>
                <div className="text-right">
                  <span className="text-vp-c-gray-1 block text-sm">
                    {`Users: ${item.usersOnline || 0}`}
                  </span>
                  <span className="text-vp-c-gray-1 text-xs">{item.name}</span>
                </div>
              </button>
            ))
          }
        </div>
      </div>
    </div>
  );
}
