import React, { useState, useRef, useEffect } from 'react';
import { useSocket } from '../hooks/Socket';
import { useDimensions } from '../hooks/Dimensions';
import { useError } from '../hooks/Error';
import ErrorMessage from '../modals/ErrorMessage';
import { RoomTabs } from './RoomTabs';
import { ChatArea } from './ChatArea';
import TrendingRooms from './TrendingRooms';

export default function ChatRoom({ user, socket, onLogin, rooms, activeRoom, joinRoom, leaveRoom }) {
    const { messages, trendingRooms } = useSocket(socket, activeRoom);
    const dimensions = useDimensions();
    const [newMessage, setNewMessage] = useState('');
    const listRef = useRef(null);

    // Use the custom error hook
    const { errorMessage, triggerError, clearError } = useError(3000);

    useEffect(() => {
        // Ensure socket is available before trying to add event listener
        if (!socket) return;
    
        // Listen for message errors from the server
        const handleMessageError = ({ reason }) => {
            triggerError(reason);
        };
    
        socket.on('messageError', handleMessageError);
    
        // Cleanup listener on unmount
        return () => {
            socket.off('messageError', handleMessageError);
        };
    }, [socket, triggerError]);

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            if(!activeRoom){return;}
            if (newMessage.trim()) {
                socket.emit('sendMessage', {
                    ticker: activeRoom.ticker,
                    type: activeRoom.type,
                    message: newMessage
                });
                setNewMessage('');
            }
        }
    };

    return (
        <div className="grid grid-cols-1 md:grid-cols-[250px_1fr_250px] gap-2 h-full">
            {/* Error Message */}
            <ErrorMessage message={errorMessage} onClear={clearError} />
    
            {/* room tabs Sidebar (Desktop) */}
            <RoomTabs
                rooms={rooms}
                activeRoom={activeRoom}
                joinRoom={joinRoom}
                leaveRoom={leaveRoom}
                trendingRooms={trendingRooms}
            />
    
            {/* Chat Area */}
            <div className="flex flex-col">
                <ChatArea
                    dimensions={dimensions}
                    messages={messages}
                    listRef={listRef}
                    newMessage={newMessage}
                    setNewMessage={setNewMessage}
                    handleKeyDown={handleKeyDown}
                    handleSendMessage={() => {
                        if (newMessage.trim()) {
                            if (!activeRoom) {
                                return;
                            }
                            socket.emit('sendMessage', {
                                ticker: activeRoom.ticker,
                                type: activeRoom.type,
                                message: newMessage
                            });
                            setNewMessage('');
                        } else {
                            triggerError('Message cannot be empty.');
                        }
                    }}
                    user={user}
                    activeRoom={activeRoom}
                    onLogin={onLogin}
                    socket={socket}
                />
            </div>
    
            {/* RoomInfo Component (Hidden on Mobile) */}
            <div className="hidden md:block">
                <TrendingRooms 
                    trendingRooms={trendingRooms} 
                    joinRoom={joinRoom}
                    currentRooms={rooms}
                />
            </div>
        </div>
    );
}