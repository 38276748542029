import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Rules from '../components/legal/rules';
import TermsOfService from '../components/legal/tos';
import PrivacyPolicy from '../components/legal/privacy';
import RoomWrapper from '../components/hooks/RoomWrapper';
import ChatRoom from '../components/chat/ChatRoom';

export const MainContent = ({ user, socket, rooms, activeRoom, joinRoom, leaveRoom, handleLogin, loading, socketReady }) => (
    <main className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-1 gap-2 p-4 h-[calc(100vh-64px)]">
        <Routes>
            <Route path="/rules" element={<Rules />} />
            <Route path="/tos" element={<TermsOfService />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route
                path="/:prefixAndRoom"
                element={<RoomWrapper joinRoom={joinRoom} socketReady={socketReady} />}
            />
            <Route
                path="/"
                element={
                    <ChatRoom
                        user={user || ''}
                        socket={socket}
                        rooms={rooms}
                        activeRoom={activeRoom}
                        joinRoom={joinRoom}
                        leaveRoom={leaveRoom}
                        onLogin={handleLogin}
                        loading={loading}
                    />
                }
            />
        </Routes>
    </main>
); 