import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { io } from 'socket.io-client';
import { useRooms } from './components/hooks/Rooms';
import { useAuth } from './hooks/useAuth';
import Header from './components/modals/Header';
import { LoadingSpinner } from './components/LoadingSpinner';
import { MainContent } from './components/MainContent';

function App() {
    const [initialized, setInitialized] = useState(false);
    const [socket, setSocket] = useState(null);
    const [socketReady, setSocketReady] = useState(false);
    
    const { 
        user, 
        setUser, 
        handleLogin, 
        handleLogout, 
        refreshAccessToken, 
        isTokenExpired 
    } = useAuth(socket, setSocket);

    const { 
        rooms, 
        activeRoom, 
        joinRoom, 
        leaveRoom, 
        setRooms, 
        loading 
    } = useRooms(socket, user?.alias || '', socketReady);

    // Initialize socket connection only once
    useEffect(() => {
        const socketInstance = io(process.env.REACT_APP_SOCKET_URL, {
            autoConnect: false,
            auth: {
                token: localStorage.getItem('authToken'),
            },
        });

        socketInstance.on('connect', () => {
            console.log('Connected to socket server');
            setSocketReady(true);
        });

        socketInstance.on('disconnect', () => {
            console.log('Disconnected from socket server');
            setSocketReady(false);
        });

        socketInstance.on('connect_error', (err) => {
            console.error('Socket connection error:', err);
            setSocketReady(false);
        });

        setSocket(socketInstance);
        
        // Cleanup on component unmount
        return () => {
            socketInstance.disconnect();
            setSocketReady(false);
        };
    }, []);

    // Load stored user and rooms on app initialization
    useEffect(() => {
        const storedUser = JSON.parse(localStorage.getItem('user'));
        const storedRooms = JSON.parse(localStorage.getItem('rooms') || '[]');

        if (storedUser) setUser(storedUser);
        if (storedRooms.length > 0) {
            // Filter out any invalid room data
            const validRooms = storedRooms.filter(room => 
                room && room.type && room.ticker
            );
            setRooms(validRooms);
        }
        setInitialized(true);
    }, [setRooms, setUser]);

    // Handle token refresh and socket reconnection
    useEffect(() => {
        if (!socket) return;

        const refreshToken = localStorage.getItem('refreshToken');
        const connectSocket = async () => {
            try {
                if (refreshToken && user) {
                    // Authenticated user flow
                    let token = localStorage.getItem('authToken');
                    
                    if (!token || isTokenExpired(token)) {
                        const newToken = await refreshAccessToken(refreshToken);
                        if (newToken) {
                            localStorage.setItem('authToken', newToken);
                            token = newToken;
                        } else {
                            handleLogout(rooms);
                            return;
                        }
                    }
                    socket.auth = { token };
                } else {
                    // Anonymous user flow
                    socket.auth = {}; // Clear any existing auth
                }
                
                socket.connect(); // Connect regardless of auth status
            } catch (error) {
                console.error('Error connecting socket', error);
                if (refreshToken) handleLogout(rooms);
            }
        };

        connectSocket();
    }, [user, socket, handleLogout, rooms, refreshAccessToken, isTokenExpired]);

    if (!initialized) {
        return <LoadingSpinner />;
    }

    return (
        <Router>
            <div className="grid grid-rows-[auto_1fr] min-h-screen bg-vp-c-bg">
                <Header 
                    user={user} 
                    onLogin={handleLogin} 
                    onLogout={() => handleLogout(rooms)} 
                />
                <MainContent 
                    user={user}
                    socket={socket}
                    rooms={rooms}
                    activeRoom={activeRoom}
                    joinRoom={joinRoom}
                    leaveRoom={leaveRoom}
                    handleLogin={handleLogin}
                    loading={loading}
                    socketReady={socketReady}
                />
            </div>
        </Router>
    );
}

export default App;